import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useHistory } from "react-router-dom";
const RedirectHandler = () => {
  const { instance } = useMsal();
  const history = useHistory();
  useEffect(() => {
    const handleRedirect = async () => {
      try {
        // Handles the redirect response
        const response = await instance.handleRedirectPromise();
        console.log("Inside redirect");
        if (response) {
          console.log("Redirect response:", response);
          history.push("/litmus/dashboard");
          
        }
      } catch (error) {
        console.error("Error handling redirect:", error);
      }
    };

    handleRedirect();
  }, [instance]);
  return <div>Loading...</div>;
};

export default RedirectHandler;
