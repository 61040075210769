import "./polyfills";
import React from "react";
import { createRoot } from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import { HashRouter } from "react-router-dom";
import "./assets/base.scss";
import Main from "./DemoPages/Main";
import configureStore from "./config/configureStore";
import { Provider } from "react-redux";
import { PublicClientApplication, LogLevel } from "@azure/msal-browser"; // MSAL import
import { MsalProvider } from "@azure/msal-react"; // MSAL provider

const store = configureStore();
const rootElement = document.getElementById("root");

// MSAL Configuration
const msalConfig = {
  auth: {
    clientId: "12bb61c0-5e38-4d77-ba87-9c9eb3c977ed", //Azure AD Client ID
    authority:
      "https://login.microsoftonline.com/61eb94f2-a637-4b88-9c8d-12b5067529d3", //Azure AD Tenant ID
    redirectUri: "https://litmus.work/redirect",
  },
  cache: {
    cacheLocation: "localStorage", // Persist in localStorage
    storeAuthStateInCookie: true, // Set for IE11/Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            break;
          case LogLevel.Info:
            console.info(message);
            break;
          case LogLevel.Verbose:
            console.debug(message);
            break;
          case LogLevel.Warning:
            console.warn(message);
            break;
          default:
            break;
        }
      },
      logLevel: LogLevel.Verbose, // Set to Verbose for more detailed logs
      piiLoggingEnabled: false,
    },
  },
};

// Create an MSAL instance
const msalInstance = new PublicClientApplication(msalConfig);

const renderApp = (Component) => (
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <HashRouter>
        <Component />
      </HashRouter>
    </MsalProvider>
  </Provider>
);

const root = createRoot(rootElement).render(renderApp(Main));

if (module.hot) {
  module.hot.accept("./DemoPages/Main", () => {
    const NextApp = require("./DemoPages/Main").default;
    root.render(renderApp(NextApp));
  });
}

serviceWorker.unregister();
