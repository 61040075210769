import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

const isTokenExpired = () => {
  const expiresAt = localStorage.getItem("msal.expiresAt");
  if (!expiresAt) return true; // If there's no expiry time, treat the token as expired
  return Date.now() >= parseInt(expiresAt, 10);
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useIsAuthenticated();
  const isTokenValid = !isTokenExpired();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && isTokenValid ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
